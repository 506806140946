import {Auth0ProviderOptions} from "@auth0/auth0-react";
import {env} from "./env";

export const REACT_APP_VERSION = "1.0.0a8";

export const APP_PATHS={
    HOME: "/",
    FAQ: "/faq",
    BASIC_DATA_EXPORT: "/basicExport",
    ADVANCED_DATA_EXPORT: "/advancedExport",
    AGENCY_PORTAL: "https://dcas-dem.my.site.com/s/login/?ec=302&startURL=%2Fs%2F",
    DEM_SALESFORCE: "https://dcas-dem.my.salesforce.com/",
    ENERGY_TOOLS: "https://nycenergytools.com/",
    PRIVACY_POLICY: "https://www.nyc.gov/home/privacy-policy.page",
    TERMS_OF_USE: "https://www.nyc.gov/home/terms-of-use.page",
    ACCESSIBILITY: "https://www.nyc.gov/site/mopd/resources/digital-accessibility.page",
    CUNY_BPL_LINK: "https://cunybpl.org/",
    DCAS_LINK: "https://www.nyc.gov/site/dcas/agencies/energy-management.page",
};

export const AUTH0_CONFIG:Auth0ProviderOptions={
    domain: env.REACT_APP_AUTH0_DOMAIN,
    clientId: env.REACT_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
        redirect_uri: `${window.location.origin}`,
        scope: env.REACT_APP_AUTH0_SCOPE,
        audience: env.REACT_APP_AUTH0_AUDIENCE,
    },
    // NOTE:
    // cacheLocation default is memory. Problem is that some browsers block third-party cookies.
    // this block causes Silent Authentication to fail on refresh
    // switching to localstorage. The following thread explains the issue in detail
    // https://community.auth0.com/t/why-is-authentication-lost-after-refreshing-my-single-page-application/56276
    useRefreshTokens: true,
    cacheLocation: "localstorage",
};

export const REPORTING_LEVEL=[
    {key: "meter", label: "Meter"},
    {key: "property", label: "Property"},
    {key: "agency", label: "Agency"},
];
export const TIMESCALE=[
    {key: "monthly", label: "Monthly"},
    {key: "annual", label: "Annual"},
];
export const YEAR_TYPE=[
    {key: "calendar", label: "Calendar Year"},
    {key: "fiscal", label: "Fiscal Year"},
];

export const REPORTING_DOWNLOAD_PAYLOAD_CONFIG:any={
    monthly: {
        min_billing_year: 2013,
        min_billing_month: 7,
        max_billing_year: 2023,
        max_billing_month: 12,
    },
    annual: {
        min_reporting_year: 2014,
        max_reporting_year: 2023,
    },
};

export const DATA_DICTIONARY_URL = "https://www.dropbox.com/scl/fi/d6syi8252ijafewkgitxq/Epsilon-Basic-Data-Export-Data-Dictionary.xlsx?rlkey=dd0ndb5j02xsioyqw0nvoy6d8&st=y9w695ye&dl=1";

export const GREY="rgba(0, 0, 0, 0.6)";
export const BLUE_LIGHT="#F3F6FB";
export const WHITE="#FFFFFF";
export const DARK_BLUE = "#0A5796";

export * from "./endpoints";
export {env} from "./env";
