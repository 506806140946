import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {Login} from "@mui/icons-material";
import {RedirectLoginOptions, AppState} from "@auth0/auth0-react";
import {APP_PATHS, DARK_BLUE} from "../../config";
import Link from "../generics/Link";
import EPSILON_LOGO_ICON from "../../static/logo_icon_white.svg";
import EPSILON_LOGO_TEXT from "../../static/logo_text_white.svg";
import BG_IMAGE from "../../static/banner_buildings.jpg";
import WithSpace from "./WithSpace";

interface Props{
    loginWithRedirect: (options?:RedirectLoginOptions<AppState>) => Promise<void>
}

/**
 * PreSplash
 * @param {Props} props
 * @return {React.ReactElement}
 */
function PreSplash(props:Props):React.ReactElement {
    return (
        <Box
            height="100vh"
            sx={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${BG_IMAGE}) lightgray 50% / cover no-repeat;`,
                display: "flex",
                flexDirection: "column",
            }}
        >
            {/* hero */}
            <WithSpace sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                color: "#FFFFFF",
                gap: "32px",
            }}
            >
                {/* header */}
                <Box>
                    <img src={EPSILON_LOGO_ICON} alt="epsilon_logo_icon" style={{width: "50px", height: "50px"}} />
                    <img src={EPSILON_LOGO_TEXT} alt="epsilon_logo_text" style={{width: "191px", height: "58px"}} />
                </Box>
                {/* body */}
                <Box>
                    <Typography variant="body1" sx={{textAlign: "center", fontSize: "24px", lineHeight: "32.02px", paddingX: {xs: "0px", sm: "96px"}}}>
                        The Epsilon platform, created by the&nbsp;
                        <Link to={APP_PATHS.CUNY_BPL_LINK} underline="always" sx={{color: "inherit"}}>
                            CUNY Building Performance Lab
                        </Link>
                            &nbsp;for the&nbsp;
                        <Link to={APP_PATHS.DCAS_LINK} underline="always" sx={{color: "inherit"}}>
                            DCAS Division of Energy Management
                        </Link>
                            &nbsp;provides a central location for building energy data and analytics.
                    </Typography>
                </Box>
                {/* login action */}
                <Button variant="contained" size="large" startIcon={<Login />} sx={{backgroundColor: "#FFFFFF", color: DARK_BLUE, ":hover": {backgroundColor: "#FFFFFF"}}} onClick={(args:React.MouseEvent):void => { props.loginWithRedirect(); }}>
                    <Typography sx={{fontSize: "15px", fontWeight: "500", lineHeight: "26px", textTransform: "capitalize"}}>Sign In</Typography>
                </Button>
            </WithSpace>
            {/* footer */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 4,
                    py: 8,
                }}
            >
                <Typography sx={{textAlign: "center", height: "24px"}}>
                    <Link to={APP_PATHS.CUNY_BPL_LINK} underline="none" color="#FFFFFF">
                        Powered by CUNY Building Performance Lab
                    </Link>
                </Typography>
            </Box>
        </Box>
    );
}

export default PreSplash;
