import {useState, Dispatch, SetStateAction, useEffect, useCallback} from "react";
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {useAuth0, Auth0ContextInterface, User, LocalStorageCache} from "@auth0/auth0-react";
import {enqueueSnackbar} from "notistack";
import _ from "lodash";
import api, {get} from "../../api";
import {env, SLACK_ROUTE} from "../../config";

interface Props{
    state:any
    setState:Dispatch<SetStateAction<any>>
}

interface CallHandler{
    loading:boolean
    error:AxiosError|null
}

interface CallHandlerReturn{
    loading:boolean
    get:(baseUrl:string, url:string) => Promise<AxiosResponse|AxiosError>
    error:AxiosError|null
    call:(config:AxiosRequestConfig) => Promise<AxiosError|AxiosResponse|undefined>
}

/**
 * getAuth0StorageKey
 * @param {LocalStorageCache} auth0Storage
 * @return {string|undefined}
 */
const getAuth0StorageKey=(auth0Storage:LocalStorageCache):string|undefined => auth0Storage.allKeys().find((k:any) => k.includes(`@@auth0spajs@@::${env.REACT_APP_AUTH0_CLIENT_ID}::${env.REACT_APP_AUTH0_AUDIENCE}`));

/**
 * slackCall
 * @param {AxiosError} payload
 * @param {User} user
 * @return {Promise<void>}
 */
export const slackCall=async (payload:AxiosError, user:User):Promise<void> => {
    // AxiosError returns raw error and AxiosResponse.
    // both are reported seperately per following
    const {response, ...rest}=payload;
    const content={
        "@@Axios Raw Payload": rest,
        "@@Axios Raw Payload Response": response||"NONE!",
    };

    await api({
        method: "post",
        url: SLACK_ROUTE,
        data: {
            blocks: [
                {type: "header", text: {type: "plain_text", text: "Log", emoji: true}},
                {
                    type: "context",
                    elements: [
                        {type: "plain_text", text: `User Email: ${user.email}`, emoji: true},
                        {type: "plain_text", text: `Environment: ${env.REACT_APP_ENVIRONMENT}`, emoji: true},
                        {type: "plain_text", text: "@zxc", emoji: true},
                    ],
                },
                {type: "divider"},
                {
                    type: "rich_text",
                    elements: [
                        {
                            type: "rich_text_preformatted",
                            elements: [
                                {type: "text", text: `${JSON.stringify(content, null, 2)}`},
                            ],
                        },
                    ],
                },
            ],
        },
    })
        .then((res:AxiosResponse) => res)
        .catch((err:AxiosError) => enqueueSnackbar("Failed Reporting Error", {variant: "error"}));
};

/**
 * useCall
 * @param {Props} props
 * @return {CallHandlerReturn}
 */
export function useCall(props:Props):CallHandlerReturn {
    const {user, logout}:Auth0ContextInterface<User>= useAuth0();
    const [state, setState]:[CallHandler, Dispatch<SetStateAction<CallHandler>>]=useState<CallHandler>({error: null, loading: false});

    /**
     * getHelper
     * @param {string} baseUrl
     * @param {string} url
     * @return {Promise<AxiosResponse|AxiosError>}
     */
    const getHelper = async (baseUrl:string, url:string):Promise<AxiosResponse|AxiosError> => {
        setState((prev:CallHandler) => ({...prev, loading: true}));
        // exit getHelper on error
        if (state.error) {
            setState((prev:CallHandler) => ({...prev, loading: false}));
            return state.error;
        }
        const res = get(url, baseUrl)
            .then((response:AxiosResponse) => {
                // set loading false
                setState((prev:CallHandler) => ({...prev, loading: false}));
                return response;
            })
            .catch((error:AxiosError) => {
                slackCall(error, user as User);
                setState({error, loading: false});
                return error;
            });
        return res;
    };

    /**
     * call
     * @param {AxiosRequestConfig} config
     * @return {Promise<AxiosError|AxiosResponse|undefined>}
     */
    const call= async (config:AxiosRequestConfig):Promise<AxiosError|AxiosResponse|undefined> => {
        if (config===undefined) return undefined;
        setState((prev:CallHandler) => ({...prev, loading: true}));
        return api(config)
            .then((res:AxiosResponse) => {
                setState((prev:CallHandler) => ({...prev, loading: false}));
                return res;
            })
            .catch((error:AxiosError) => {
                slackCall(error, user as User);
                setState({error, loading: false});
                return error;
            });
    };

    /**
     * resolveToken
     * @return {Promise<AxiosError>}
     */
    const resolveToken=useCallback(async ():Promise<AxiosError> => {
        // auth0 storage access
        const auth0Storage=new LocalStorageCache();
        // resolving auth0 user session key
        const key=getAuth0StorageKey(auth0Storage);
        // escape if key is undefined
        if (key===undefined) return {status: 401, message: "Unable to Resolve Auth0 LocalStorageCache key", response: {data: {error: "undefined_storage", storage: auth0Storage}}} as AxiosError;
        // resolve user session object
        const auth0StorageUserSession:any=auth0Storage.get(key);

        // construct resquest config
        const request:AxiosRequestConfig={
            method: "POST",
            url: `https://${env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
            headers: {"content-type": "application/x-www-form-urlencoded"},
            data: new URLSearchParams({
                grant_type: "refresh_token",
                client_id: env.REACT_APP_AUTH0_CLIENT_ID,
                refresh_token: auth0StorageUserSession.body.refresh_token,
            }),
        };

        // make api call
        return axios(request)
            .then((response:AxiosResponse) => {
                // update axios global Authorization header
                api.defaults.headers.common.Authorization=`Bearer ${response?.data?.access_token}`;
                // update user session
                localStorage.setItem(key, JSON.stringify({...auth0StorageUserSession, body: {...auth0StorageUserSession.body, ...response.data}}));
                return {status: response.status, message: "Token Resolved Successfully!", response: {data: {access_token: `${response?.data?.access_token?.substring(0, 10)}... Response Was Intentionally Omitted`}}} as AxiosError;
            })
            .catch((error:AxiosError) => error);
    }, []);

    useEffect(() => {
        // intercepting on incoming response
        const interceptorId=api.interceptors.response.use(
            (response:AxiosResponse) => response,
            async (error:AxiosError) => {
                if (error?.response?.status===403) {
                    // resolving new access_token
                    const tokenResponse:AxiosError=await resolveToken();

                    // terminate from token reselution AND logout user when inactivity lifetime value exhausted
                    if (["invalid_grant", "undefined_storage"].includes((tokenResponse.response as AxiosResponse)?.data.error)) {
                        api.interceptors.response.eject(interceptorId);
                        logout({logoutParams: {returnTo: `${window.location.origin}/?signout=true&inactive=true`}});
                        return Promise.reject(error);
                    }

                    // terminate from token reselution (AxiosError)
                    if (tokenResponse.status!==200) return Promise.reject(tokenResponse);

                    // re-attempt same failed api call
                    return get(error.config?.url as string)
                        .then((r:AxiosResponse) => r)
                        .catch((e:AxiosError) => {
                            slackCall(e, user as User);
                            setState({error: e, loading: false});
                            return Promise.reject(e);
                        });
                }
                return Promise.reject(error);
            },
        );

        // ejecting on hook unmounting
        return () => {
            api.interceptors.response.eject(interceptorId);
        };
    }, [resolveToken, user, logout]);

    return {loading: state.loading, get: getHelper, error: state.error, call};
}
